import { render, staticRenderFns } from "./provinceListIndex.pug?vue&type=template&id=4ac00504&scoped=true&lang=pug&"
import script from "./provinceListIndex.ts?vue&type=script&lang=ts&"
export * from "./provinceListIndex.ts?vue&type=script&lang=ts&"
import style0 from "./provinceListIndex.scss?vue&type=style&index=0&id=4ac00504&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4ac00504",
  null
  
)

export default component.exports