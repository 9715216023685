import Component from 'vue-class-component'
import { Watch } from 'vue-property-decorator'
import forEach from 'lodash/forEach'
import debounce from 'lodash/debounce'
import isEmpty from 'lodash/isEmpty'
import sortBy from 'lodash/sortBy'
import startCase from 'lodash/startCase'

// Base Page
import BasePage from '@/pages/Base'

// Services
import ProvinceService from '@/services/Province'
import CountryService from '@/services/Country'
import moment from 'moment'

@Component({})
export default class ProvinceList extends BasePage {
  constructor() {
    super()
  }

  listProductsDialog: boolean = false
  listProductsObject: any = {}
  id_user: number = 0
  allTotalProducts: number = 0
  // Search
  searchLoading: boolean = false
  searchInput: string = ''
  searchLabel: string = 'Search By Name...'
  searchHint: string = ''
  searchIcon: string = 'search'

  // Table
  tableHeaders: object[] = [
    {
      text: 'ID',
      align: 'left',
      sortable: true,
      value: 'id'
    },
    {
      text: 'Name',
      align: 'left',
      sortable: true,
      value: 'name'
    },
    {
      text: 'Action',
      align: 'center',
      sortable: false,
      value: 'action',
      class: 'action-table-width'
    }
  ]
  tableItems: object[] = []
  tableTotalItems: string | number = 0
  tableRowsPerPageItems: number[] = [5, 10, 15, 25, 50, 100]
  tablePagination: any = {
    sortBy: 'id',
    page: 1,
    rowsPerPage: 25,
    descending: true,
    totalItems: 0
  }
  tableLoading: boolean = true

  provinceDialog: boolean = false
  isEdit: boolean = false
  provinceDataDialog: any = {}

  countries: any = {
    items: [],
    keyword: null,
    loading: false,
    selected: null
  }

  selectedDelete: any = {}
  selectedIdProvince: number = 0
  showDeleteModal: boolean = false

  filter: any = {
    item: [],
    selected: null,
    keyword: null,
    label: 'Filter Country...',
    hint: 'Apply Country Filter Here',
    icon: 'mdi-filter-variant',
    loading: false
  }

  selectedCountry: string = ''

  searchCountries: Function
  searchFilter: Function

  async mounted() {
    await this.getFilterItem()
    this.searchCountries = debounce(this.getCountries, 500)
    this.searchFilter = debounce(this.getFilterItem, 500)
  }

  @Watch('countries.keyword')
  async onChangedCountry() {
    await this.searchCountries()
  }

  @Watch('filter.keyword')
  async onChangedFIlter() {
    await this.searchFilter()
  }

  @Watch('tablePagination', { deep: true })
  async onChanged() {
    this.$vuetify.goTo(0)
    this.getProvinces()
  }

  async onClearSearch() {
    this.searchInput = ''
    this.getProvinces()
  }

  async onClearFilter() {
    this.filter.selected = null
  }

  async getProvinces() {
    try {
      this.tableLoading = true
      const opts = {
        params: {
          'page[num]': this.tablePagination.page,
          'page[limit]': this.tablePagination.rowsPerPage,
          sort: this.tablePagination.descending ? '-' + this.tablePagination.sortBy : this.tablePagination.sortBy,
          // include: "countries"
          'filter[name][like]': ''
        }
      }
      if (this.searchInput != '') {
        opts.params = {
          ...opts.params,
          'filter[name][like]': this.searchInput
        }
      } else {
        var textFilter = 'filter[name][like]'
        delete opts.params[textFilter]
      }

      if (this.filter.selected) {
        var textFilter = 'filter[country_id][is]'
        opts.params[textFilter] = this.filter.selected.id
      } else {
        var textFilter = 'filter[country_id][is]'
        delete opts.params[textFilter]
      }

      const response = await ProvinceService.getProvinces(opts)
      const responseData = response.data.data
      const responseMeta = response.data.meta
      this.tableItems = []
      forEach(responseData, dataProvince => {
        this.tableItems.push(dataProvince.attributes)
      })
      this.tableTotalItems = responseMeta.pagination.total
    } catch (error) {
      this.catchHandler(error)
    } finally {
      this.tableLoading = false
    }
  }

  async getFilterItem() {
    try {
      this.filter.loading = true
      const opts: any = {
        params: {
          'page[num]': 1,
          'page[limit]': 10
        }
      }

      if (!isEmpty(this.filter.keyword)) {
        var textFilter = 'filter[name][like]'
        opts.params[textFilter] = this.filter.keyword
      } else {
        var textFilter = 'filter[name][like]'
        delete opts.params[textFilter]
      }
      const response = await CountryService.getCountries(opts)
      const responseData = response.data.data
      this.filter.item = []
      for (const dataCountry of responseData) {
        this.filter.item.push(dataCountry.attributes)
      }
      this.filter.item = sortBy(this.filter.item, 'name')
    } catch (error) {
      this.catchHandler(error)
    } finally {
      this.filter.loading = false
    }

  }

  async getCountries() {
    try {
      this.countries.loading = true
      const opts: any = {
        params: {
          'page[num]': 1,
          'page[limit]': 10
        }
      }

      if (!isEmpty(this.countries.keyword)) {
        var textFilter = 'filter[name][like]'
        opts.params[textFilter] = this.countries.keyword
      } else {
        var textFilter = 'filter[name][like]'
        delete opts.params[textFilter]
      }

      const response = await CountryService.getCountries(opts)
      const responseData = response.data.data
      this.countries.items = []
      for (const dataCountry of responseData) {
        this.countries.items.push(dataCountry.attributes)
      }
      this.countries.items = sortBy(this.countries.items, 'name')
      if (this.provinceDataDialog.country_id) {
        await this.getCountryById()
      }
    } catch (error) {
      this.catchHandler(error)
    } finally {
      this.countries.loading = false
    }
  }

  async getCountryById() {
    const response = await CountryService.getCountryById(this.provinceDataDialog.country_id)
    this.countries.items.push(response.data.data.attributes)
  }

  openCreateDialog() {
    this.isEdit = false
    this.provinceDataDialog = {
      name: null,
      country_id: null
    }
    this.provinceDialog = true
    this.getCountries()
  }

  closeDialog() {
    this.provinceDialog = false
  }

  async addProvince() {
    try {
      this.showLoading({ text: 'Saving...' })
      this.provinceDialog = false
      const payload = {
        name: this.provinceDataDialog.name,
        country_id: this.provinceDataDialog.country_id
      }
      await ProvinceService.setProvinces(payload)
      this.showSnackbar({
        text: 'Saved Successfully!',
        color: 'green',
        timeout: 1500
      })
      this.getProvinces()
    } catch (error) {
      this.catchHandler(error)
    } finally {
      this.closeLoading()
    }
  }

  openDeleteModal(props) {
    this.selectedDelete = props.item
    this.showDeleteModal = true
  }

  async deleteProvince() {
    try {
      this.showLoading({ text: 'Saving...' })
      this.showDeleteModal = false
      const response = await ProvinceService.deleteProvince(this.selectedDelete.id)
      this.showSnackbar({
        text: startCase(response.status === 200 ? 'Success' : 'Failed with HTTP error: ' + response.status),
        color: response.status === 200 ? 'green' : 'red',
        timeout: 1500
      })
      this.getProvinces()
    } catch (error) {
      this.catchHandler(error)
    } finally {
      this.closeLoading()
    }
  }

  openEditDialog(props) {
    this.isEdit = true
    this.selectedIdProvince = props.item.id
    this.provinceDataDialog = {
      name: props.item.name,
      country_id: props.item.country_id
    }
    this.getCountries()
    this.provinceDialog = true
  }

  async updateProvince() {
    try {
      this.showLoading({ text: 'Saving...' })
      this.provinceDialog = false
      const payload = {
        name: this.provinceDataDialog.name,
        country_id: this.provinceDataDialog.country_id
      }
      await ProvinceService.updateProvince(this.selectedIdProvince, payload)
      this.showSnackbar({
        text: 'Saved Successfully!',
        color: 'green',
        timeout: 1500
      })
      this.getProvinces()
    } catch (error) {
      this.catchHandler(error)
    } finally {
      this.closeLoading()
    }
  }

  async onFilterChange() {
    if (this.filter.selected) {
      this.selectedCountry = this.filter.selected.name + ' > '
    } else {
      this.selectedCountry = ''
    }
    await this.getProvinces()
  }
}
